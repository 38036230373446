// Import Major lib
import React, { useState, useRef } from "react";
import ReCAPTCHA from "react-google-recaptcha";
// Import Antd Components
import { Layout, Typography, Input, Button, Select, notification } from "antd";
import { Grid, Modal } from "@nextui-org/react";
import confetti from "canvas-confetti";

// Import Antd Icons
import { WarningOutlined } from "@ant-design/icons";

// Import validator
import EthereumAddress from "ethereum-address";

// Impoirt Actions
import { requestClaim, verifyToken, requestNewAPIKey } from "../actions/action";

// constants
import { SITE_KEY } from "../config";

// Import resource
import LogoSm from "../img/logo-sm.png";
// Import StyleSheet
import {
  HeaderStyle,
  ContentStyle,
  ButtonStyle,
  SelectStyle,
  FooterStyle,
} from "../style/page";
import "../style/page.css";

const MainPage = () => {
  const { Header, Content, Footer } = Layout;
  const { Title } = Typography;

  const [address, setAddress] = useState("");
  const [network, setNetwork] = useState("Select Network");

  const [createdApiKey, setCreatedApiKey] = useState("");

  const [processing, setProcessing] = useState(false);
  const [modalShow, setModalshow] = useState(false);
  const [showKeyModal, setShowKeyModal] = useState(false);
  const [apiCreating, setApiCreating] = useState(false);
  const captchaRef = useRef(null);

  const selectOptions = [
    {
      label: "Ethereum Goerli",
      value: "goerli",
      default: true,
    },
    {
      label: "BSC Testnet",
      value: "bsctest",
    },
    {
      label: "Polygon Mumbai",
      value: "mumbai",
    },
    {
      label: "Avalanche Fuji",
      value: "avalanchetest",
    },
  ];

  const networks = ["goerli", "bsctest", "mumbai", "avalanchetest"];

  const onOpenKeyModal = () => {
    setShowKeyModal(true);
  };

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(createdApiKey);
  };

  const onRequestNewAPIKey = async () => {
    const captchaToken = captchaRef.current.getValue();

    if (captchaToken) {
      const isCaptchaSolved = await verifyToken(captchaToken);
      if (!isCaptchaSolved) {
        notification.error({
          message: "Captcha Error",
          description: "Captcha must be exactly solved",
        });
        return;
      } else {
        captchaRef.current.reset();
      }
    } else {
      notification.error({
        message: "Captcha Error",
        description: "You must complete the captcha verification",
      });
      return;
    }

    setApiCreating(true);
    try {
      const apiKey = await requestNewAPIKey();
      setCreatedApiKey(apiKey);
      setApiCreating(false);
    } catch (error) {
      setApiCreating(false);
      throw error;
    }
  };

  const requestToken = async () => {
    if (address === "" || !EthereumAddress.isAddress(address)) {
      notification.error({
        message: "Invalid input",
        description: "Please input valid ethereum address",
      });
      return;
    }
    if (networks.indexOf(network) < 0) {
      notification.error({
        message: "Invalid input",
        description: "Please select network",
      });
      return;
    }

    const captchaToken = captchaRef.current.getValue();

    if (captchaToken) {
      const isCaptchaSolved = await verifyToken(captchaToken);
      if (!isCaptchaSolved) {
        notification.error({
          message: "Captcha Error",
          description: "Captcha must be exactly solved",
        });
        return;
      } else {
        captchaRef.current.reset();
      }
    } else {
      notification.error({
        message: "Captcha Error",
        description: "You must complete the captcha verification",
      });
      return;
    }

    const payload = { address, network };

    setProcessing(true);
    try {
      const response = await requestClaim(payload);

      console.log(response);

      if (response.data.success) {
        confetti({ particleCount: 100, origin: { y: 0.7, x: 0.55 } });
        notification.success({
          message: "Success",
          description: `You claimed 100 WFNB successfully`,
        });
        notification.success({
          message: "Transaction is on process",
          description: response.data.hash,
        });
      } else {
        notification.error({
          message: "Double claim Error",
          description: response.data.message,
        });
      }

      setProcessing(false);
    } catch (error) {
      console.log(error);
      notification.error({
        message: "Ooops",
        description: "Internal server error",
      });
      setProcessing(false);
    }
  };

  return (
    <Layout>
      <Header style={HeaderStyle}>
        <Grid.Container>
          <Grid xs={12} justify="space-between" alignItems="center">
            <img src={LogoSm} className="logo-sm" />

          </Grid>
          <Grid xs={12} justify="center">
            <Title level={1} style={{ color: "#ffffff", fontSize: "45px" }}>
              Wrapped Fanbase Coin (WFNB) Faucet
            </Title>
          </Grid>
        </Grid.Container>
      </Header>
      <Content style={ContentStyle}>
        <Grid.Container justify="center" gap={2}>
          <Grid xs={12} justify="center">
            <Title level={1}> Request WFNB </Title>
          </Grid>
          <Grid xs={8} justify="center" direction="column">
            <Title level={4}>
              Claim WFNB on one of the supported blockchain testnets so you can
              test the Fanbase DeFi ecosystem.
            </Title>
          </Grid>
          <Grid xs={8.4} justify="center">
            <Input
              bordered
              prefix="0x..."
              className="address-input"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              disabled={processing}
            />
          </Grid>
          <Grid.Container justify="center" gap={2}>
            <Grid xs={12} justify="center">
              <Grid xs={5.9}></Grid>
            <Grid xs={6.8}>
              <Select
                placeholder="Select Network"
                options={selectOptions}
                style={SelectStyle}
                size="large"
                value={network}
                onChange={(e) => setNetwork(e)}
                disabled={processing}
              />
            </Grid>
            <Grid xs={8}>
              <Button
                type="primary"
                style={ButtonStyle}
                onClick={requestToken}
                disabled={processing}
              >
                {processing ? "Processing..." : "Claim 100 WFNB"}
              </Button>
            </Grid>
            </Grid>
          </Grid.Container>
          <Grid xs={12} justify="center">
            <ReCAPTCHA sitekey={SITE_KEY} ref={captchaRef} />
          </Grid>  <Grid xs={12} justify="center">
          <Button onClick={onOpenKeyModal}>Get API Key</Button>
          </Grid>
          <Grid xs={10} justify="center" direction="column">
            <Title level={5} style={{ color: "#ff9100" }}>
              <WarningOutlined /> &nbsp; Claim 100 test WFNB, per day, on each
              device.
            </Title>
            <Title level={5} style={{ color: "#ff9100" }}>
              You can claim 100 test tokens per day.
            </Title>
          </Grid>
          <Grid xs={10} justify="center" direction="column">
            <Title level={1}> Earn WFNB </Title>
            <Title level={4}>
              Gain access to our{" "}
              <a
                href="https://t.me/@fanbaseio_bot"
                rel="noreferrer"
                target="_blank"
              >
                mainnet faucet
              </a>{" "}
              and automatically earn real WFNB when you tweet and follow us! Or,
              learn more about{" "}
              <a
                href="https://defi.fanbase.io/"
                rel="noreferrer"
                target="_blank"
              >
                Fanbase DeFi
              </a>{" "}
              and download{" "}
              <a
                href="https://chrome.google.com/webstore/detail/fanbaseio/ehkdpgmlbmklaiibhcdggpbohgggjibl"
                rel="noreferrer"
                target="_blank"
              >
                Fanbase Wallet.
              </a>
            </Title>
          </Grid>
        </Grid.Container>
      </Content>
      <Footer style={FooterStyle}>
        <Grid.Container>
          <Grid xs={3} justify="right">
            <a
              href="https://www.fanbase.io/#/about"
              rel="noreferrer"
              target="_blank"
            >
              About
            </a>
          </Grid>
          <Grid xs={4} justify="center">
            <a
              href="https://www.fanbase.io/#/privacy"
              rel="noreferrer"
              target="_blank"
            >
              Policy
            </a>
          </Grid>
          <Grid xs={2} justify="left">
            <a
              href="https://www.fanbase.io/#/terms"
              rel="noreferrer"
              target="_blank"
            >
              Terms
            </a>
          </Grid>
          <Grid xs={2} justify="left">
            <a
              href="https://fanbase-io.gitbook.io/docs/"
              rel="noreferrer"
              target="_blank"
            >
              API Doc
            </a>
          </Grid>
        </Grid.Container>
      </Footer>
      <Modal
        closeButton={true}
        open={modalShow}
        onClose={() => setModalshow(false)}
      >
        <Grid.Container>
          <Grid xs={12} justify="center">
            <Title level={3}> Successfully Claimed</Title>
          </Grid>
        </Grid.Container>
      </Modal>
      <Modal
        closeButton={false}
        open={showKeyModal}
        onClose={() => setShowKeyModal(false)}
      >
        <Modal.Header style={HeaderStyle}>
          <Grid.Container xs={12} justify="center">
            <Grid xs={12} justify="center">
              <Title level={2} style={{ color: "#ffffff" }}>
                {" "}
                Get API Key
              </Title>
            </Grid>
          </Grid.Container>
        </Modal.Header>
        <Modal.Body>
          <Grid.Container xs={12} gap={2}>
            <Grid xs={12}>
              <Input
                value={createdApiKey}
                suffix={<Button onClick={copyToClipboard}>Copy</Button>}
              ></Input>
            </Grid>
            <Grid xs={12}>
              <Button
                type="primary"
                loading={apiCreating}
                size="large"
                onClick={onRequestNewAPIKey}
                style={{ width: "100%" }}
              >
                Create New API Key
              </Button>
            </Grid>
          </Grid.Container>
        </Modal.Body>
      </Modal>
    </Layout>
  );
};

export default MainPage;
