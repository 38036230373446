import axios from 'axios'

import { API_BASE_ENDPOINT, GITHUB_OAUTH_CLIENT_ID, GITHUB_OAUTH_CLIENT_SECRET, GITHUB_OAUTH_REDIRECT_URL } from '../config'

export const requestClaim = async (pyaload) => {
    try {
        const response = await axios.post(`${API_BASE_ENDPOINT}/claim`, pyaload)

        return response.data
    }
    catch (error) {
        throw error
    }
}

export const verifyToken = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_ENDPOINT}/verifyCaptcha`, { token: data })

        return response.data.data
    }
    catch (error) {
        throw error
    }
}

export const register = async (data) => {
    try {
        const response = await axios.post(`${API_BASE_ENDPOINT}/register`, { mail: data })

        return response.data.data
    }
    catch (error) {
        throw error
    }
}

export const requestNewAPIKey = async () => {
    try {
        const response = await axios.post(`${API_BASE_ENDPOINT}/createAPIKey`)

        return response.data.data
    }
    catch (error) {
        throw error
    }
}