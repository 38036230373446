export const HeaderStyle = {
    height: 'fit-content',
    paddingTop: '20px',
    background: '#0162d0',
}

export const ContentStyle = {
    height: 'fit-content',
    // paddingTop: '50px'
}

export const ButtonStyle = {
    height: '100%'
}

export const SelectStyle = {
    width: '100%',
    // height: '40px'
}

export const FooterStyle = {
    height: 'fit-content',
    paddingTop: '20px',
    background: '#0162d0',
    color: '#ffffff'
} 